import React from "react";
import Main from "components/layout/Main";
import VideoCard from "components/VideoCard";
import VideoGrid from "components/VideoGrid";
import Block from "components/Block";
import HeaderWarriors from "components/HeaderWarriors";

import ScrollToTop from "components/ScrollToTop";

import WlLogo from "assets/images/wol/warrior-logo.svg";
import data from "data/warriors-of-light/chapters";

export default function WarriorsOfLightPage() {
  return (
    <Main page={"wol"} pagetitle="Warriors Of Light">
      <HeaderWarriors logo={WlLogo} />
      <Block appearance="warriors">
        <VideoGrid>
          {data.chapters.map((chapter) => (
            <VideoCard appearance="card" key={chapter.title} {...chapter} />
          ))}
        </VideoGrid>
      </Block>
      <ScrollToTop modifier={["slim-top"]} guides={0} />
    </Main>
  );
}
