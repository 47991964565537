import React from "react";
import "./HeaderWarriors.scss";

export default function HeaderWarriors({ logo }) {
  return (
    <div className="WL_Header">
      <img className="WL_Logo" src={logo} alt="Warriors of Light Logo" />
    </div>
  );
}
